import Loading from "vue-loading-overlay";
import axios from "axios";
import PaginationNav from "../../../components/PaginationNav.vue";
import PaginationMobile from "../../../components/PaginationMobile.vue";

import * as config from "@/config.json";
import moment from "moment/moment";

export default {
  name: "DiscountCouponList",
  components: {
    Loading,
    PaginationNav,
    PaginationMobile
  },

  props: ["source"],

  data: () => {
    return {
      pagination: {},
      paginationRange: 11,
      token: "",
      isLoading: false,
      expiredCoupons: 0,
      activeCoupons: 0,
      usedCoupons: 0,
      reservedCoupons: 0,
      firstRegistration: 0,
      totalCoupons: 0,
      perPage: 8,
      currentPage: 1,
      selectedOptionApp: null,
      optionsApp: [],
      coupons: [],
      fieldsCoupons: [{
          key: "id",
          label: "ID"
        },
        {
          key: "title",
          label: "Título"
        },
        {
          key: "description",
          label: "Descrição"
        },
        {
          key: "typeDescription",
          label: "Tipo"
        },
        {
          key: "value",
          label: "Valor"
        },
        {
          key: "startDate",
          label: "Início"
        },
        {
          key: "expiryAt",
          label: "Vencimento"
        },
        {
          key: "isFirstRegistration",
          label: "Primeiro Registro"
        },
        {
          key: "showDetails",
          label: ""
        },
        {
          key: "action",
          label: ""
        }
      ],
      paramsDetails: {},
      pagePagination: null,
      selectedDateOption: null,
      dateOptions: [{
          value: null,
          text: 'Selecione o Tipo de Data'
        },
        {
          value: 1,
          text: 'Início do Cupom'
        },
        {
          value: 2,
          text: 'Vencimento do Cupom'
        }
      ],

      startDate: moment(new Date()).format("YYYY-MM-DD"),
      endDate: moment(new Date()).format("YYYY-MM-DD"),

      selectedTypeDiscountOption: null,
      typeDiscountOption: [{
          value: null,
          text: 'Selecione o Tipo'
        },
        {
          value: 1,
          text: 'Por Valor'
        },
        {
          value: 2,
          text: 'Por Porcentagem'
        },
      ],
      selectedRadio: 0,
      optionsSelectRadio: [{
          value: 0,
          text: 'Todos'
        },
        {
          value: 1,
          text: 'Ativos'
        },
        {
          value: 2,
          text: 'Expirados'
        },
        {
          value: 3,
          text: 'Não Expiram'
        },
      ],
      totalRecords: 0,
    };
  },
  methods: {
    async clearLocalStoragePage() {
      localStorage.removeItem('selectedOptionAppCoupon');
      localStorage.removeItem('selectedDateOptionCoupon');
      localStorage.removeItem('startDateCoupon');
      localStorage.removeItem('endDateCoupon');
      localStorage.removeItem('selectedTypeDiscountOption');
      localStorage.removeItem('selectedRadioCoupon');
      localStorage.removeItem('currentPageCoupon');
    },

    async redirect(ref) {

      if (ref == 'discount-coupon-manager') {
        localStorage.removeItem('redirectDiscount');
        localStorage.setItem('redirectDiscount', 'discount-coupon-list');
      } else {
        await this.clearLocalStoragePage();
      }
      this.$router.push(ref);
    },

    async buildParamsDetails(id, title, description) {
      this.paramasDetails = {
        id,
        title,
        description
      }
    },

    async deleteCouponDiscount(data) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/coupons/${data.id}`;

      try {
        const response = await axios({
          url,
          method: "DELETE",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          await this.$alert(
            `Cupom ID: ${data.id} - Descrição: ${data.description} excluído!`,
            "Sucesso",
            "success"
          );

          await this.searchCoupons(this.currentPage);
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async showDetailsCoupon(coupon) {
      await this.buildParamsDetails(coupon.id, coupon.title, coupon.description)

      this.$router.push({
        name: "DetailsCouponDiscount",
        params: this.paramasDetails
      });
    },

    async getApps() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/apps/user/v1`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.optionsApp = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.name,
            };
          }));

          if (this.optionsApp.length == 1) {
            if (!this.selectedOptionApp) {
              this.selectedOptionApp = this.optionsApp[0].value;
            }
          }

          if (this.selectedOptionApp) {
            if (!this.pagePagination) {
              await this.searchCoupons(this.currentPage);
            } else {
              await this.navigate(this.pagePagination)
            }
          }

        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsApp = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          this.$alert(
            `Ocorreu um erro ao buscar os aplicativos! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async navigate(page) {
      await this.searchCoupons(page);
    },

    clearApps() {
      this.selectedOptionApp = null;
      this.searchCoupons(this.currentPage);
    },

    async buildCoupons(appId, dateType, startDate, endDate, type, selectedRadio, page, perPage) {
      let params = new Object();

      if (appId) {
        params.appId = appId;
      }

      if (dateType && startDate && endDate) {
        let initialDate = moment(startDate).format("YYYY-MM-DD 00:00:00")
        let finalDate = moment(endDate).format("YYYY-MM-DD 23:59:59")

        if (!initialDate || !finalDate) {
          return null;
        }

        if (finalDate < initialDate) {
          this.getNotification(
            "Aviso",
            "A data final deve ser maior que a data inicial!",
            "warning"
          );

          return null;
        } else {
          params.startDate = initialDate;
          params.endDate = finalDate;
          params.dateType = dateType == 1 ? 0 : 1;
        }
      }

      if (type) {
        params.type = type;
      }

      if (parseInt(selectedRadio) > 0) {
        if (parseInt(selectedRadio) == 1) {
          params.isExpired = false
        }

        if (parseInt(selectedRadio) == 2) {
          params.isExpired = true
        }

        if (parseInt(selectedRadio) == 3) {
          params.isNeverExpire = true
        }
      }

      params.page = page;
      params.pageSize = perPage;

      return params;
    },

    async searchCoupons(page) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/coupons/search`;
      const buildCustomers = await this.buildCoupons(this.selectedOptionApp, this.selectedDateOption, this.startDate, this.endDate, this.selectedTypeDiscountOption, this.selectedRadio, page, this.perPage);

      localStorage.setItem('selectedOptionAppCoupon', this.selectedOptionApp);
      localStorage.setItem('selectedDateOptionCoupon', this.selectedDateOption);
      localStorage.setItem('startDateCoupon', this.startDate);
      localStorage.setItem('endDateCoupon', this.endDate);
      localStorage.setItem('selectedTypeDiscountOption', this.selectedTypeDiscountOption);
      localStorage.setItem('selectedRadioCoupon', this.selectedRadio);
      localStorage.setItem('currentPageCoupon', page);


      try {
        const response = await axios({
          url,
          method: "POST",
          data: buildCustomers,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.pagination = response.data;
          this.pagination.rowsPerPage = this.perPage;
          this.pagination.paginationRange = this.paginationRange;

          this.coupons = response.data.items;

          this.expiredCoupons = response.data.expiredCoupons;
          this.activeCoupons = response.data.activeCoupons;
          this.usedCoupons = response.data.usedCoupons;
          this.reservedCoupons = response.data.reservedCoupons;
          this.firstRegistration = response.data.firstRegistrationCoupons;
          this.totalCoupons = response.data.totalCoupons;
          this.totalRecords = response.data.records;
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.coupons = [];
        this.pagination = [];
        this.totalRecords = 0;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os cupons de desconto! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");

    this.pagePagination = parseInt(localStorage.getItem('currentPageCoupon'));


    if (localStorage.getItem('selectedOptionAppCoupon') == 'null' || localStorage.getItem('selectedOptionAppCoupon') == null) {
      this.selectedOptionApp = null;
    } else {
      this.selectedOptionApp = parseInt(localStorage.getItem('selectedOptionAppCoupon'));
    }

    if (localStorage.getItem('selectedDateOptionCoupon') == 'null' || localStorage.getItem('selectedDateOptionCoupon') == null) {
      this.selectedDateOption = null
    } else {
      this.selectedDateOption = parseInt(localStorage.getItem('selectedDateOptionCoupon'));
    }

    if (localStorage.getItem('selectedTypeDiscountOption') == 'null' || localStorage.getItem('selectedTypeDiscountOption') == null) {
      this.selectedTypeDiscountOption = null;
    } else {
      this.selectedTypeDiscountOption = parseInt(localStorage.getItem('selectedTypeDiscountOption'));
    }

    if (localStorage.getItem('selectedRadioCoupon') == 'null' || localStorage.getItem('selectedRadioCoupon') == null) {
      this.selectedRadio = 0;
    } else {
      this.selectedRadio = parseInt(localStorage.getItem('selectedRadioCoupon'));
    }

    this.startDate = localStorage.getItem('startDateCoupon') ?? moment(new Date()).format("YYYY-MM-DD");
    this.endDate = localStorage.getItem('endDateCoupon') ?? moment(new Date()).format("YYYY-MM-DD");

    if (!this.pagePagination) {
      await this.searchCoupons(this.currentPage);
    } else {
      await this.navigate(this.pagePagination)
    }

    await this.getApps();

    this.isLoading = false;
  },
};
import Loading from "vue-loading-overlay";
import axios from "axios";
import PaginationNav from "../../components/PaginationNav.vue";
import PaginationMobile from "../../components/PaginationMobile.vue";

import * as config from "@/config.json";

export default {
  name: "ProductsSite",
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
  },
  props: ["source"],
  data: () => {
    return {
      permissions: '',
      isAllowedEditProduct: false,
      pagination: {},
      paginationRange: 11,
      currentPage: 1,
      perPage: 8,
      token: null,
      product: '',
      inactiveProduct: false,
      isInactiveProduct: false,
      productsSite: [],
      fieldsProductsSite: [{
          key: 'isInactive',
          label: 'Ativo'
        },
        {
          key: 'imageUrl',
          label: 'Foto'
        },
        {
          key: 'sku',
          label: 'Barras'
        },
        {
          key: 'description',
          label: 'Descrição'
        },
        {
          key: 'availableQuantity',
          label: 'Qtd Estoque'
        },
        {
          key: 'minimumQuantity',
          label: 'Qtd Mínima'
        },
        {
          key: 'maximumQuantity',
          label: 'Qtd Máxima'
        },
        {
          key: 'idealQuantity',
          label: 'Qtd Ideal'
        },
        {
          key: 'supplyQuantity',
          label: 'Sugestão Reposição'
        },
        {
          key: 'costPrice',
          label: 'Vlr Custo'
        },
        {
          key: 'salePrice',
          label: 'Vlr Venda'
        },
        {
          key: 'promotionPrice',
          label: 'Vlr Promoção'
        },
        {
          key: 'loyalPrice',
          label: 'Vlr Fidelizado'
        },
        {
          key: 'groupDescription',
          label: 'Grupo'
        },
        {
          key: "action",
          label: ""
        },
      ],
      totalRecords: null,
      selectedProductsRadio: 0,
      optionsSelectProductsRadio: [{
          item: 0,
          name: 'Todos',
        },
        {
          item: 1,
          name: 'Ativos',
        },
        {
          item: 2,
          name: 'Inativos',
        },
      ],
      selectedOption: null,
      options: [{
          value: null,
          text: "--- Selecione ---",
        }, {
          value: 1,
          text: "Descrição",
        },
        {
          value: 2,
          text: "Barras",
        },
        {
          value: 3,
          text: "Qtd Estoque",
        },
        {
          value: 4,
          text: "Grupo",
        },
      ],

      selectedOptionStockType: null,
      optionsStockType: [{
          value: null,
          text: "--- Selecione ---",
        }, {
          value: 1,
          text: "Ideal",
        },
        {
          value: 2,
          text: "Mínima",
        },
        {
          value: 3,
          text: "Máxima",
        },
      ],

      selectedOptionStock: null,
      optionsStock: [{
          value: null,
          text: "--- Selecione ---",
        },
        {
          value: 1,
          text: "Igual",
        },
        {
          value: 2,
          text: "Abaixo",
        },
        {
          value: 3,
          text: "Acima",
        },
      ],

      isLoading: false,
      companyId: null,
      siteId: null,
      fantasy: '',
      stockProduct: 0,
      maximumQuantityProduct: 0,
      minimumQuantityProduct: 0,
      idealQuantityProduct: 0,
      costProduct: 0,
      valueProduct: 0,
      promotionPriceProduct: 0,
      loyalPriceProduct: 0,
      state: null,
      description: '',
      pagePagination: null,
    };
  },

  methods: {
    getUserPermissions() {
      this.permissions = JSON.parse(sessionStorage.getItem("permissions"))

      const cost_Price = this.permissions.find(
        (permission) => permission.command === "cost_price"
      )

      if (cost_Price) {
        this.isAllowedEditProduct = true
      } else {
        this.isAllowedEditProduct = false
      }
    },

    buildParamsProductsSite(companyId, siteId, option, optionStock, optionQuantity, selectedProductsRadio, pagina) {
      let params = new Object();

      if (companyId != null) {
        params.companyId = companyId
      }

      if (siteId != null) {
        params.siteId = siteId
      }

      if (option == 1) {
        params.description = this.product;
      }

      if (option == 2) {
        params.sku = this.product;
      }

      if (option == 3 && optionQuantity != null) {
        params.optionStock = optionStock
        params.optionQuantity = optionQuantity
      }

      if (option == 4) {
        params.groupDescription = this.product;
      }

      if (selectedProductsRadio == 1) {
        params.isInactive = false
      }

      if (selectedProductsRadio == 2) {
        params.isInactive = true
      }

      params.pageSize = this.perPage;
      params.page = pagina;

      return params;
    },

    async clearLocalStorage() {
      localStorage.removeItem('companyIdProductSite')
      localStorage.removeItem('siteIdProductSite')
      localStorage.removeItem('fantasyProductSite')
      localStorage.removeItem('currentPageProductSite');
    },

    async redirect(ref) {
      await this.clearLocalStorage()
      this.$router.push(ref)
    },

    async readSiteParams() {
      if (this.$route.params.companyId) {
        localStorage.setItem('companyIdProductSite', this.$route.params.companyId)
      }

      if (this.$route.params.siteId) {
        localStorage.setItem('siteIdProductSite', this.$route.params.siteId)
      }

      if (this.$route.params.fantasy) {
        localStorage.setItem('fantasyProductSite', this.$route.params.fantasy)
      }

      this.companyId = localStorage.getItem('companyIdProductSite')
      this.siteId = localStorage.getItem('siteIdProductSite')
      this.fantasy = localStorage.getItem('fantasyProductSite')


      await this.searchProductsSite(this.currentPage)
    },

    async searchProductsSite(pagina) {
      const url = `${config.default.urlBase}/sites/products/search`;
      const params = this.buildParamsProductsSite(this.companyId, this.siteId, this.selectedOption, this.selectedOptionStockType, this.selectedOptionStock, this.selectedProductsRadio, pagina);

      try {
        const response = await axios({
          url: url,
          method: "get",
          params: params,
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        localStorage.setItem('currentPageProductSite', pagina);

        if (response.status == 200) {
          this.pagination = response.data;
          this.pagination.rowsPerPage = this.perPage;
          this.pagination.paginationRange = this.paginationRange;

          this.totalRecords = response.data.records;

          const products = await Promise.all(
            response.data.items.map(async (product) => {
              return {
                ...product,
                availableQuantity: String(product.availableQuantity).replace('.', ',').trim(),
                maximumQuantity: String(product.maximumQuantity).replace('.', ',').trim(),
                minimumQuantity: String(product.minimumQuantity).replace('.', ',').trim(),
                idealQuantity: String(product.idealQuantity).replace('.', ',').trim(),
                supplyQuantity: String(product.supplyQuantity).replace('.', ',').trim(),
              };
            })
          )
          
          this.productsSite = products
        }

      } catch (error) {
        console.error({
          errorMessage: `[searchProductsSite] ${error}`,
        });

        this.isLoading = false;

        this.productsSite = [];
        this.pagination = [];
        this.totalRecords = 0;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os produtos! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    cleanSearch() {
      this.product = "";
      this.searchProductsSite(this.currentPage);
    },

    cleanStockOption() {
      this.selectedOptionStock = null
      this.searchProductsSite(this.currentPage);
    },

    cleanStockType() {
      this.selectedOptionStockType = null
      this.searchProductsSite(this.currentPage);
    },

    navigate(page) {
      this.searchProductsSite(page);
    },

    verifyState() {
      if (
        this.stockProduct != '' &&
        this.valueProduct != ''
      )
        return true
      else
        return false
    },

    async showEditProduct(product) {
      this.description = product.description
      this.productSiteId = product.productSiteId
      this.inactiveProduct = product.isInactive == 1 ? true : false

      this.stockProduct = product.availableQuantity.replace(',', '.')
      this.stockProduct = parseFloat(this.stockProduct)
      
      this.costProduct = product.costPrice.replace('R$', '').trim()
      this.costProduct = this.costProduct.replace(',', '.')
      this.costProduct = parseFloat(this.costProduct)

      this.valueProduct = product.salePrice.replace('R$', '').trim()
      this.valueProduct = this.valueProduct.replace(',', '.')
      this.valueProduct = parseFloat(this.valueProduct)
      
      this.promotionPriceProduct = product.promotionPrice.replace('R$', '').trim()
      this.promotionPriceProduct = this.promotionPriceProduct.replace(',', '.')
      this.promotionPriceProduct = parseFloat(this.promotionPriceProduct)

      this.loyalPriceProduct = product.loyalPrice.replace('R$', '').trim()
      this.loyalPriceProduct = this.loyalPriceProduct.replace(',', '.')
      this.loyalPriceProduct = parseFloat(this.loyalPriceProduct)

      this.maximumQuantityProduct = product.maximumQuantity.replace(',', '.').trim()
      this.maximumQuantityProduct = parseFloat(this.maximumQuantityProduct)

      this.minimumQuantityProduct = product.minimumQuantity.replace(',', '.').trim()
      this.minimumQuantityProduct = parseFloat(this.minimumQuantityProduct)

      this.idealQuantityProduct = product.idealQuantity.replace(',', '.').trim()
      this.idealQuantityProduct = parseFloat(this.idealQuantityProduct)
      
      this.isInactiveProduct = product.isInactiveProduct == 1 ? true : false

      this.$refs.editProduct.show();
    },

    async buildDataProduct(productSiteId, isInactive, availableQuantity, costPrice, salePrice, promotionPrice, loyalPrice, minimumQuantity, maximumQuantity, idealQuantity) {
      const availableQuantityStr = availableQuantity
      const costPriceStr = costPrice
      const salePriceStr = salePrice
      const promotionPriceStr = promotionPrice
      const loyalPriceStr = loyalPrice
      const minimumQuantityStr = minimumQuantity
      const maximumQuantityStr = maximumQuantity
      const idealQuantityStr = idealQuantity

      return {
        productSiteId: parseInt(productSiteId),
        isInactive: Boolean(isInactive),
        availableQuantity: parseFloat(availableQuantityStr),
        costPrice: parseFloat(costPriceStr),
        salePrice: parseFloat(salePriceStr),
        promotionPrice: parseFloat(promotionPriceStr),
        loyalPrice: parseFloat(loyalPriceStr),
        minimumQuantity: parseFloat(minimumQuantityStr),
        maximumQuantity: parseFloat(maximumQuantityStr),
        idealQuantity: parseFloat(idealQuantityStr)
      }
    },

    focus(id) {
      document.getElementById(id).focus();
    },

    async closeModalEditProduct() {
      await this.$refs.editProduct.hide();
      this.pagePagination = localStorage.getItem('currentPageProductSite');

      if (!this.pagePagination) {
        await this.searchProductsSite(this.currentPage)
      } else {
        this.navigate(this.pagePagination)
      }
    },

    async updateProduct() {
      this.isLoading = true

      const url = `${config.default.urlBase}/sites/products`;
      const data = await this.buildDataProduct(
        this.productSiteId,
        this.inactiveProduct,
        this.stockProduct,
        this.costProduct,
        this.valueProduct,
        this.promotionPriceProduct,
        this.loyalPriceProduct,
        this.minimumQuantityProduct,
        this.maximumQuantityProduct,
        this.idealQuantityProduct,
      )

      try {
        const response = await axios({
          url,
          method: "PATCH",
          data,
          headers: {
            authorization: `Bearer ${this.token}`,
          },
        });

        if (response.status == 200) {
          this.isLoading = false

          await this.closeModalEditProduct();

          await this.$alert(
            "Produto Atualizado!",
            "Sucesso",
            "success"
          );
        }
      } catch (error) {
        console.error({
          errorMessage: `[UpdateProduct] ${error}`,
        });

        this.isLoading = false;


        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao atualizar o produto! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },
  },

  async updated() {
    this.state = await this.verifyState()
  },

  computed: {
    stockProductState() {
      return this.stockProduct ? true : false
    },
    valueProductState() {
      return this.valueProduct ? true : false
    },
  },

  async beforeMount() {
    this.isLoading = true;

    this.token = localStorage.getItem("token");

    this.pagePagination = localStorage.getItem('currentPageProductSite');

    await this.readSiteParams()
    this.getUserPermissions()

    this.isLoading = false;
  },
};
<template>
  <div>
    <b-navbar toggleable="lg" variant="primary">
      <b-button
        class="itensNavbar"
        v-b-toggle.sidebar-menu
        variant="dark"
        size="sm"
      >
        <b-icon icon="list" variant="secondary"></b-icon>
      </b-button>
      <img
        @click="redirect('/home')"
        class="imgLogoNav"
        alt="logo"
        src="@/assets/logo_express_white.png"
      />

      <div id="calendar" class="callout calendar-day">
        <b-row>
          <b-col cols="12">
            <div class="grid-x align-middle align-middle">
              <div class="shrink cell">
                <h1>
                  {{ this.momentInstance.format("DD") }}
                  <img
                    src="@/assets/calendar.png"
                    alt="home"
                    class="imgCalendar"
                  />
                </h1>

                <h6>
                  {{ this.momentInstance.format("[de] MMMM [de] YYYY") }}
                  {{ this.momentInstance.format("dddd [-] HH:mm:ss") }}
                </h6>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="ml-auto">
        <b-row class="left">
          <span>
            <i class="fa fa-user-circle" style="color: #fe5c3a" />
            <b class="dataUser">
              {{ user }}
            </b>
          </span>
        </b-row>

        <br />

        <b-row class="left">
          <span>
            <i class="far fa-id-card" style="color: #fe5c3a" />
            <b class="dataUser">
              {{ userType }}
            </b>
          </span>
        </b-row>

        <br />

        <b-row class="left">
          <span>
            <i class="far fa-building" style="color: #fe5c3a" />
            <b class="dataUser" style="margin-rigth: 20px">
              {{ divisionName }}
            </b>
          </span>
        </b-row>
      </div>
    </b-navbar>
  </div>
</template>
<script>
import moment from "moment";
moment.locale("pt-br");

export default {
  name: "NavBar",

  data() {
    return {
      user: "",
      userType: "",
      divisionName: "",
      momentInstance: moment(),
    };
  },

  methods: {
    async redirect(ref) {
      this.clearLocalStorageSavedPages();
      this.$router.push({path: ref}).catch(() => {})
    },

    clearLocalStorageSavedPages() {
      //*** Coupon Discount ***//
      localStorage.removeItem("selectedOptionAppCoupon");
      localStorage.removeItem("selectedDateOptionCoupon");
      localStorage.removeItem("startDateCoupon");
      localStorage.removeItem("endDateCoupon");
      localStorage.removeItem("selectedTypeDiscountOption");
      localStorage.removeItem("selectedRadioCoupon");
      localStorage.removeItem("currentPageCoupon");
      localStorage.removeItem("appsId-CouponsDiscount");
      localStorage.removeItem("startDateCouponDiscount");
      localStorage.removeItem("startTimeCouponDiscount");
      localStorage.removeItem("titleCouponDiscount");
      localStorage.removeItem("descriptionCouponDiscount");
      localStorage.removeItem("expiry");
      localStorage.removeItem("expiryAtCouponDiscount");
      localStorage.removeItem("endTimeCouponDiscount");
      localStorage.removeItem("typeCouponDiscount");
      localStorage.removeItem("valueCouponDiscount");
      localStorage.removeItem("idCustomersCouponDiscount");
      localStorage.removeItem("push");

      //*** Promotions ***//
      localStorage.removeItem("selectedOptionCompanyPromotion");
      localStorage.removeItem("descriptionPromotion");
      localStorage.removeItem("selectedRadioPromotion");
      localStorage.removeItem("selectedDatePromotionOption");
      localStorage.removeItem("startDatePromotion");
      localStorage.removeItem("endDatePromotion");
      localStorage.removeItem("currentPagePromotion");

      //*** Campaigns ***//
      localStorage.removeItem("selectedOptionDateCompany");
      localStorage.removeItem("startDateCompany");
      localStorage.removeItem("endDateCompany");
      localStorage.removeItem("selectedOptionFilterCompany");
      localStorage.removeItem("filterCompany");
      localStorage.removeItem("currentPageCampaignCompany");

      //*** Users ***/
      localStorage.removeItem("currentPageUsers");
      localStorage.removeItem("selectedOptionFilterUser");
      localStorage.removeItem("filterUser");
      localStorage.removeItem("redirectUserPermissions");

      //*** App Banner ***/
      localStorage.removeItem("selectedOptionCompanyAppBanner");

      //*** Totem Banner ***//
      localStorage.removeItem("selectedOptionCompanyTotemBanner");

      //*** Search Speechs ***//
      localStorage.removeItem("currentPageSearchSpeechs");
      localStorage.removeItem("selectedOptionCompanySearchSpeechs");
      localStorage.removeItem("selectedOptionSiteSearchSpeechs");
      localStorage.removeItem("selectedOptionFilterSearchSpeechs");
      localStorage.removeItem("filterSearchSpeechs");

      //*** Customer Page ***//
      localStorage.removeItem("selectedOptionAppCustomerPage");
      localStorage.removeItem("selectedOptionCustomerPage");
      localStorage.removeItem("customerCustomerPage");
      localStorage.removeItem("selectedRadioCustomerPage");
      localStorage.removeItem("selectedRadioCustomerBlockCustomerPage");
      localStorage.removeItem("selectedPageCustomerPage");

      //*** Site Products ***//
      localStorage.removeItem("companyIdProductSite");
      localStorage.removeItem("siteIdProductSite");
      localStorage.removeItem("fantasyProductSite");
      localStorage.removeItem("currentPageProductSite");

      //*** Monitoring ***/
      localStorage.removeItem("siteIdMonitoringSite");

      //*** Orders ***/
      localStorage.removeItem("orderId");
      localStorage.removeItem("companyIdOrder");
      localStorage.removeItem("siteIdOrder");
      localStorage.removeItem("filterOption");
      localStorage.removeItem("startDateOrder");
      localStorage.removeItem("endDateOrder");
      localStorage.removeItem("currentPageSales");
      localStorage.removeItem("selectedFilterOptionSales");
    },
  },

  mounted() {
    setInterval(() => {
      this.momentInstance = moment();
    }, 1000);
  },

  created() {
    this.user = localStorage.getItem("usuarioLogado");
    this.divisionName = localStorage.getItem("divisionName");

    if (localStorage.getItem("typeUser") == "organization") {
      this.userType = "Organização";
    } else if (localStorage.getItem("typeUser") == "company") {
      this.userType = "Empresa";
    } else {
      this.userType = "Loja";
    }
  },
};
</script>

<style scopped>
.callout.calendar-day {
  padding: 0.8rem 1.9rem;
  margin-left: auto;
}

.callout.calendar-day h1 {
  margin: 0 -7rem 0 5rem;
  color: #fe5c3a;
}

.callout.calendar-day h6 {
  margin: 0;
  color: white;
}

.imgCalendar {
  width: 12%;
  margin-bottom: 2%;
}

.fullDate {
  margin-top: 5%;
}

.imgLogoNav {
  height: 100%;

  cursor: pointer;
}
.navbar {
  height: 70px;
}
.itensNavbar {
  margin-left: 15px;
  margin-right: 15px;
}

.fa-user-circle:before {
  content: "\f2bd";
  font-size: larger;
}

.fa-building:before {
  content: "\f2bd";
  font-size: larger;
}

.fa-id-card:before {
  content: "\f2bd";
  font-size: medium;
}

.left {
  float: left;
}

.dataUser {
  color: #fff;
  font-size: 1rem;
  margin-right: 10px;
}

@media (max-width: 1000px) {
  #calendar {
    display: none;
  }
  .dataUser {
    font-size: x-small;
  }

  .fa-user-circle {
    font-size: x-small;
  }

  .fa-building:before {
    font-size: x-small;
  }

  .fa-id-card:before {
    font-size: x-small;
  }

  .navbar {
    line-height: normal;
  }
}

@media (max-width: 450px) {
  .navbar {
    line-height: normal;
    height: 65px;
  }

  .dataUser {
    font-size: xx-small;
  }

  .fa-user-circle {
    font-size: xx-small;
  }

  .fa-building:before {
    font-size: xx-small;
  }

  .fa-id-card:before {
    font-size: xx-small;
  }

  .imgLogoNav {
    height: 30px;
  }
}

@media (max-width: 365px) {
  .itensNavbar {
    margin-left: 5px;
    margin-right: 5px;
  }

  .imgLogoNav {
    height: 25px;
  }
}
</style>

<template>
  <div>
    <b-sidebar
      id="sidebar-menu"
      aria-labelledby="sidebar-menu-title"
      no-header
      shadow
      bg-variant="primary"
      text-variant="white"
      backdrop
      backdrop-variant="dark"
    >
      <template #footer="{ hide }">
        <div class="d-flex text-light align-items-center px-1 py-1">
          <strong class="mr-auto"></strong>
          <b-button
            variant="outline-secondary"
            no-border
            size="sm"
            @click="hide"
            >X</b-button
          >
        </div>
      </template>

      <template #default="{ hide }">
        <div class="p-3">
          <div class="d-flex align-items-center flex-column">
            <h4 id="sidebar-menu">Lojas Express</h4>
            <div class="menu"></div>
          </div>

          <nav class="mb-3">
            <b-nav vertical>
              <b-nav-item
                to="/home"
                @click="hide, clearLocalStorageSavedPages()"
              >
                <b-icon icon="house"></b-icon>
                Início
              </b-nav-item>

              <b-nav-item-dropdown
                id="Usuarios"
                toggle-class="text-white"
                text="Usuários"
                right
              >
                <template slot="button-content">
                  <i class="fas fa-id-card-alt"></i>
                  Usuários
                </template>

                <b-nav-item
                  to="/users-list"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -50px"
                >
                  <b-icon icon="table"></b-icon>
                  Consulta
                </b-nav-item>

                <b-nav-item
                  to="/users-manager"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -50px"
                >
                  <b-icon icon="plus-square-fill"></b-icon>
                  Cadastro
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="Produtos"
                toggle-class="text-white"
                text="Produtos"
                right
              >
                <template slot="button-content">
                  <i class="fas fa-list"></i>
                  Produtos
                </template>

                <b-nav-item
                  to="/products-list"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -50px"
                >
                  <b-icon icon="table"></b-icon>
                  Consulta
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="Clientes"
                toggle-class="text-white"
                text="Clientes"
                right
              >
                <template slot="button-content">
                  <i class="fas fa-users"></i>
                  Clientes
                </template>

                <b-nav-item
                  to="customer-list"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -50px"
                >
                  <b-icon icon="table"></b-icon>
                  Consulta
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="Vendas"
                toggle-class="text-white"
                text="Vendas"
                right
              >
                <template slot="button-content">
                  <i class="fas fa-clipboard-list"></i>
                  Vendas
                </template>

                <b-nav-item
                  to="/orders-list"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -50px"
                >
                  <b-icon icon="table"></b-icon>
                  Consulta
                </b-nav-item>

                <b-nav-item
                  to="/order-items-list"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -50px"
                >
                  <i class="fas fa-list"></i>

                  Itens Vendidos
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="BannerApp"
                toggle-class="text-white"
                text="BannerApp"
                right
              >
                <template slot="button-content">
                  <i class="far fa-image"></i>
                  Banners App
                </template>

                <b-nav-item
                  to="/banners-app-list"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -30px"
                >
                  <b-icon icon="table"></b-icon>
                  Consulta
                </b-nav-item>

                <b-nav-item
                  to="/banners-app-manager"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -30px"
                >
                  <b-icon icon="plus-square-fill"></b-icon>
                  Cadastro
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="BannerTotem"
                toggle-class="text-white"
                text="BannerTotem"
                right
              >
                <template slot="button-content">
                  <i class="far fa-image"></i>
                  Banners Totem
                </template>

                <b-nav-item
                  to="/banners-list"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -30px"
                >
                  <b-icon icon="table"></b-icon>
                  Consulta
                </b-nav-item>

                <b-nav-item
                  to="/banners-manager"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -30px"
                >
                  <b-icon icon="plus-square-fill"></b-icon>
                  Cadastro
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="TotemSpeach"
                toggle-class="text-white"
                text="TotemSpeach"
                right
              >
                <template slot="button-content">
                  <i class="far fa-comment-alt"></i>
                  Falas do Totem
                </template>

                <b-nav-item
                  to="/bot-speechs"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -2px"
                >
                  <b-icon icon="table"></b-icon>
                  Consulta Falas
                </b-nav-item>

                <b-nav-item
                  to="/bot-bounded"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -2px"
                >
                  <b-icon icon="table"></b-icon>
                  Consulta Vínculos
                </b-nav-item>

                <b-nav-item
                  to="/bot"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -2px"
                >
                  <b-icon icon="plus-square-fill"></b-icon>
                  Cadastro
                </b-nav-item>

                <b-nav-item
                  to="/bot-bound"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -2px"
                >
                  <b-icon icon="chat-square-text"></b-icon>
                  Vínculo
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="Discount"
                toggle-class="text-white"
                text="Discount"
                right
              >
                <template slot="button-content">
                  <i class="fas fa-tag"></i>
                  Cupons Desconto
                </template>

                <b-nav-item
                  to="discount-coupon-list"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -5px"
                >
                  <b-icon icon="table"></b-icon>
                  Consulta
                </b-nav-item>

                <b-nav-item
                  to="discount-coupon-manager"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -5px"
                >
                  <b-icon icon="plus-square-fill"></b-icon>
                  Cadastro
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="Discount"
                toggle-class="text-white"
                text="Discount"
                right
              >
                <template slot="button-content">
                  <i class="fas fa-paper-plane"></i>
                  Campanha Push
                </template>

                <b-nav-item
                  to="push-campaign-list"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -5px"
                >
                  <b-icon icon="table"></b-icon>
                  Consulta
                </b-nav-item>

                <b-nav-item
                  to="push-campaign-manager"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -5px"
                >
                  <b-icon icon="plus-square-fill"></b-icon>
                  Cadastro
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="Promotions"
                toggle-class="text-white"
                text="Promotions"
                right
              >
                <template slot="button-content">
                  <i class="fas fa-percentage"></i>
                  Promoções
                </template>

                <b-nav-item
                  to="promotions"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -5px"
                >
                  <b-icon icon="table"></b-icon>
                  Consulta
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown
                id="Management"
                toggle-class="text-white"
                text="Management"
                right
              >
                <template slot="button-content">
                  <i class="fas fa-cog"></i>
                  Gerencial
                </template>

                <b-nav-item
                  to="/gateway-documents"
                  @click="hide, clearLocalStorageSavedPages()"
                  style="margin-left: -5px"
                >
                  <i class="fas fa-address-book"></i>
                  Gateway Documentos
                </b-nav-item>
              </b-nav-item-dropdown>

              <b-nav-item
                to="/monitoring-pos"
                @click="hide, clearLocalStorageSavedPages()"
              >
                <b-icon icon="display"></b-icon>
                Monitoramento
              </b-nav-item>

              <b-nav-item
                to="/login"
                @click="hide, clearLocalStorageSavedPages()"
              >
                <b-icon icon="door-open"></b-icon>
                Sair
              </b-nav-item>
            </b-nav>
          </nav>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  name: "SideBar",
  data() {
    return {
      typeUser: "",
    };
  },

  methods: {
    clearLocalStorageSavedPages() {
      //*** Coupon Discount ***//
      localStorage.removeItem("selectedOptionAppCoupon");
      localStorage.removeItem("selectedDateOptionCoupon");
      localStorage.removeItem("startDateCoupon");
      localStorage.removeItem("endDateCoupon");
      localStorage.removeItem("selectedTypeDiscountOption");
      localStorage.removeItem("selectedRadioCoupon");
      localStorage.removeItem("currentPageCoupon");
      localStorage.removeItem("appsId-CouponsDiscount");
      localStorage.removeItem("startDateCouponDiscount");
      localStorage.removeItem("startTimeCouponDiscount");
      localStorage.removeItem("titleCouponDiscount");
      localStorage.removeItem("descriptionCouponDiscount");
      localStorage.removeItem("expiry");
      localStorage.removeItem("expiryAtCouponDiscount");
      localStorage.removeItem("endTimeCouponDiscount");
      localStorage.removeItem("typeCouponDiscount");
      localStorage.removeItem("valueCouponDiscount");
      localStorage.removeItem("idCustomersCouponDiscount");
      localStorage.removeItem("push");

      //*** Promotions ***//
      localStorage.removeItem("selectedOptionCompanyPromotion");
      localStorage.removeItem("descriptionPromotion");
      localStorage.removeItem("selectedRadioPromotion");
      localStorage.removeItem("selectedDatePromotionOption");
      localStorage.removeItem("startDatePromotion");
      localStorage.removeItem("endDatePromotion");
      localStorage.removeItem("currentPagePromotion");

      //*** Campaigns ***//
      localStorage.removeItem("selectedOptionDateCompany");
      localStorage.removeItem("startDateCompany");
      localStorage.removeItem("endDateCompany");
      localStorage.removeItem("selectedOptionFilterCompany");
      localStorage.removeItem("filterCompany");
      localStorage.removeItem("currentPageCampaignCompany");

      //*** Users ***/
      localStorage.removeItem("currentPageUsers");
      localStorage.removeItem("selectedOptionFilterUser");
      localStorage.removeItem("filterUser");
      localStorage.removeItem("redirectUserPermissions");

      //*** App Banner ***/
      localStorage.removeItem("selectedOptionCompanyAppBanner");

      //*** Totem Banner ***//
      localStorage.removeItem("selectedOptionCompanyTotemBanner");

      //*** Search Speechs ***//
      localStorage.removeItem("currentPageSearchSpeechs");
      localStorage.removeItem("selectedOptionCompanySearchSpeechs");
      localStorage.removeItem("selectedOptionSiteSearchSpeechs");
      localStorage.removeItem("selectedOptionFilterSearchSpeechs");
      localStorage.removeItem("filterSearchSpeechs");

      //*** Customer Page ***//
      localStorage.removeItem("selectedOptionAppCustomerPage");
      localStorage.removeItem("selectedOptionCustomerPage");
      localStorage.removeItem("customerCustomerPage");
      localStorage.removeItem("selectedRadioCustomerPage");
      localStorage.removeItem("selectedRadioCustomerBlockCustomerPage");
      localStorage.removeItem("selectedPageCustomerPage");

      //*** Site Products ***//
      localStorage.removeItem("companyIdProductSite");
      localStorage.removeItem("siteIdProductSite");
      localStorage.removeItem("fantasyProductSite");
      localStorage.removeItem("currentPageProductSite");

      //*** Monitoring ***/
      localStorage.removeItem("siteIdMonitoringSite");

      //*** Orders ***/
      localStorage.removeItem("orderId");
      localStorage.removeItem("companyIdOrder");
      localStorage.removeItem("siteIdOrder");
      localStorage.removeItem("filterOption");
      localStorage.removeItem("startDateOrder");
      localStorage.removeItem("endDateOrder");
      localStorage.removeItem("currentPageSales");
      localStorage.removeItem("selectedFilterOptionSales");
    },
  },

  beforeMount() {
    this.typeUser = localStorage.getItem("typeUser");
  },
};
</script>

<style lang="scss" src="../assets/scss/sidebar.scss" scoped />





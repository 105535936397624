import * as config from "@/config.json";

import Loading from "vue-loading-overlay";
import axios from "axios";
import PaginationNav from "../../components/PaginationNav.vue";
import PaginationMobile from "../../components/PaginationMobile.vue";
import moment from "moment/moment";
import profileImageNotFound from '@/assets/userNoPhoto.png'


export default {
  name: "CustomerList",
  components: {
    Loading,
    PaginationNav,
    PaginationMobile,
  },
  props: ["source"],
  data: () => {
    return {
      pagination: {},
      paginationRange: 11,
      currentPage: 1,
      perPage: 12,
      customer: "",
      token: null,
      customers: [],
      fieldsCustomers: [{
          key: "editCustomer",
          label: "Editar",
        },
        {
          key: "profileImage",
          label: "Foto",
        },
        {
          key: "inactive",
          label: "Ativo",
        },
        {
          key: "id",
          label: "Id",
        },
        {
          key: "name",
          label: "Nome",
        },
        {
          key: "document",
          label: "Documento",
        },
        {
          key: "email",
          label: "E-mail",
        },
        {
          key: "phone",
          label: "Telefone",
        },
        {
          key: "birthDate",
          label: "Data de Nascimento",
        },
        {
          key: "totalDevices",
          label: "Dispositivos",
        },
        {
          key: "isBlocked",
          label: "Bloqueado",
        },
        {
          key: "action",
          label: "Ação",
        },
        {
          key: "customerHistory",
          label: "Histórico"
        },
      ],
      selectedOption: null,
      options: [{
          value: null,
          text: "Nome",
        },
        {
          value: 1,
          text: "Telefone",
        },
        {
          value: 2,
          text: "E-mail",
        },
        {
          value: 3,
          text: "Documento",
        },
      ],
      isLoading: false,
      selectedOptionBlocked: null,
      optionBlocked: [],
      selectedOptionBlockReason: null,
      optionBlockReason: [],
      selectedOptionApp: null,
      optionsApp: [],
      typeUser: null,
      totalRows: 0,
      totalRecords: 0,

      selectedRadio: 0,
      optionsSelectCustomerRadio: [{
          value: 0,
          text: 'Todos'
        },
        {
          value: 1,
          text: 'Ativos'
        },
        {
          value: 2,
          text: 'Inativos'
        },
      ],

      selectedRadioCustomerBlock: 0,
      optionsSelectCustomerBlock: [{
          value: 0,
          text: 'Todos'
        },
        {
          value: 1,
          text: 'Bloqueados'
        },
        {
          value: 2,
          text: 'Desbloqueados'
        },
        {
          value: 3,
          text: 'Conta Cancelada'
        },
      ],
      customerId: null,
      customerName: '',
      action: '',
      observation: '',
      isCustomerBlocked: false,
      customerDocument: '',
      userId: null,
      userName: '',
      type: '',
      customerHistoriesDocument: null,
      customerHistoriesId: null,
      customerHistoriesName: "",
      customerHistories: [],
      fieldsCustomersHistories: [{
          key: "createdAt",
          label: "Data",
        },
        {
          key: "userName",
          label: "Usuário",
        },
        {
          key: "reasonDescription",
          label: "Motivo",
        },
        {
          key: "reason",
          label: "Observação",
        },
      ],

      paginationCustomerHistories: {},
      paginationRangeCustomerHistories: 11,
      currentPageCustomerHistories: 1,
      perPageCustomerHistories: 10,
      selectedPageCustomer: null,
      tabIndex: 0,

      customerSessions: [],
      fieldsCustomersSessions: [{
          key: "organization",
          label: "Organização",
        },
        {
          key: "company",
          label: "Empresa",
        },
        {
          key: "site",
          label: "Loja",
        },
        {
          key: "createdAt",
          label: "Acesso",
        },
        {
          key: "active",
          label: "Cliente Dentro Loja",
        },
        {
          key: "customerLeftSession",
          label: "Cliente Abandonou a Loja",
        },
      ],
      paginationCustomerSessions: {},
      paginationRangeCustomerSessions: 11,
      currentPageCustomerSessions: 1,
      perPageCustomerSessions: 10,

      selectedOptionDate: null,
      optionsDate: [{
          value: null,
          text: "Sem Intervalo de Datas",
        },
        {
          value: 1,
          text: "Accesso à Loja",
        },
      ],
      endDate: null,
      startDate: null,
      modalCustomerId: null,
      modalCustomerName: '',
      modalCustomerDocument: '',
      modalCustomerPhone: '',
      modalCustomerEmail: '',
      modalCustomerInactive: false,
      modalCustomerBirthDate: '',
      modalCustomerIsChecked: false,
      profileImage: null,
      profileId: null,
      profileName: '',
      modalProfileImage: null,
    };
  },
  methods: {
    redirect(ref) {
      this.removeLocalStorageCustomerPage();

      if (ref == "Home") {
        this.$router.push(`/home`);
      }
    },

    openProfileImage(customer) {
      this.profileImage = customer.profileImage ? customer.profileImage : profileImageNotFound
      this.profileId   = customer.id;
      this.profileName = customer.name;
      
      this.$refs.profileImage.show();
    },

    async buildCustomers(appIds, option, type, status, blockedReasonId, page, pageSize) {
      const data = new Object();


      const appIdsArray = new Array();
      appIdsArray.push(appIds);

      const appIdsSelected = new Array(appIdsArray.join(","));
      data.appsId = appIdsSelected

      if (option == null) {
        if (this.customer) {
          data.name = this.customer;
        }
      }

      if (option == 1) {
        data.phone = this.customer;
      }

      if (option == 2) {
        data.email = this.customer;
      }

      if (option == 3) {
        data.document = this.customer;
      }

      if (type == 1) {
        data.inactive = false;
      }

      if (type == 2) {
        data.inactive = true;
      }

      if (status == 1) {
        data.isBlocked = true;
      }

      if (status == 2) {
        data.isBlocked = false;
      }

      if (status == 3) {
        data.unsubscribed = true;
      }

      if (blockedReasonId != null && status == 1 || blockedReasonId != null && status == 2) {
        data.blockedReasonId = blockedReasonId;
      }


      data.page = page;
      data.pageSize = pageSize;

      return data;
    },

    async getApps() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/apps/user/v1`;

      try {
        const response = await axios({
          url,
          method: "get",
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.optionsApp = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.name,
            };
          }));

          if (this.optionsApp.length == 1) {
            if (!this.selectedOptionApp) {
              this.selectedOptionApp = this.optionsApp[0].value;
            }
          }

          if (this.selectedOptionApp) {
            if (!this.selectedPageCustomer) {
              await this.getCustomers(this.currentPage);
            } else {
              await this.navigate(this.selectedPageCustomer)
            }
          }
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.optionsApp = [];

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          this.$alert(
            `Ocorreu um erro ao buscar as empresas! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async removeLocalStorageCustomerPage() {
      localStorage.removeItem("selectedOptionAppCustomerPage")
      localStorage.removeItem("selectedOptionCustomerPage")
      localStorage.removeItem("customerCustomerPage")
      localStorage.removeItem("selectedRadioCustomerPage")
      localStorage.removeItem("selectedRadioCustomerBlockCustomerPage")
      localStorage.removeItem("selectedPageCustomerPage")
    },

    async setLocalStorageCustomerPage(page) {
      await this.removeLocalStorageCustomerPage();

      localStorage.setItem("selectedOptionAppCustomerPage", this.selectedOptionApp)
      localStorage.setItem("selectedOptionCustomerPage", this.selectedOption)
      localStorage.setItem("customerCustomerPage", this.customer)
      localStorage.setItem("selectedRadioCustomerPage", this.selectedRadio)
      localStorage.setItem("selectedRadioCustomerBlockCustomerPage", this.selectedRadioCustomerBlock)
      localStorage.setItem("selectedPageCustomerPage", page)

      await this.getLocalStorage();

    },

    async getCustomers(page) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/customers/search`;
      const data = await this.buildCustomers(this.selectedOptionApp, this.selectedOption, this.selectedRadio, this.selectedRadioCustomerBlock, this.selectedOptionBlocked, page, this.perPage);

      try {
        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        await this.setLocalStorageCustomerPage(page)
        
        if (response.status == 200) {
          this.isLoading = false;

          this.pagination = response.data;
          this.pagination.rowsPerPage = this.perPage;
          this.pagination.paginationRange = this.paginationRange;

          this.customers = response.data.items;
          this.totalRows = response.data.records;
          this.totalRecords = response.data.records;

        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        this.customers = [];
        this.pagination = [];
        this.totalRecords = 0;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          );
        } else {
          await this.$alert(
            `Ocorreu um erro ao buscar os clientes! ${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }
      }
    },

    async getNotification(title, body, type) {
      this.$vToastify.setSettings({
        position: "bottom-center",
      });
      await this.$vtNotify({
        title: title,
        body: body,
        type: type,
        duration: 3000,
      });
    },

    async cleanSearch() {
      this.customer = "";

      if (this.selectedOptionApp) {
        if (!this.selectedPageCustomer) {
          await this.getCustomers(this.currentPage);
        } else {
          await this.navigate(this.selectedPageCustomer)
        }
      }
    },

    async cleanBlockedReason() {
      this.selectedOptionBlocked = null;

      if (this.selectedOptionApp) {
        if (!this.selectedPageCustomer) {
          await this.getCustomers(this.currentPage);
        } else {
          await this.navigate(this.selectedPageCustomer)
        }
      }
    },

    async navigate(page) {
      await this.getCustomers(page);
    },

    async navigateCustomerHistories(page) {
      await this.searchCustomerHistory(page)
    },

    async navigateCustomerSessions(page) {
      await this.searchCustomerSession(page)
    },


    async chooseStatusCustomer(appId, status) {
      let unLock

      if (status == 1) {
        unLock = false
      } else if (status == 2) {
        unLock = true
      }

      this.selectedOptionBlocked = null
      await this.getBlockedReasons(appId, unLock)
    },

    async buildParamsBlockeReasons(appId, unLock) {
      return {
        appId,
        unLock
      }
    },

    async getBlockedReasons(appId, unLock) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/customers/blocked-reasons`;
      const params = await this.buildParamsBlockeReasons(appId, unLock)
      try {
        const response = await axios({
          url,
          method: "GET",
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.optionBlocked = []
          this.selectedOptionBlocked = null

          this.optionBlocked = [{
            value: null,
            text: 'Selecione uma Opção'
          }];

          const mappedOptionBlocked = await Promise.all(response.data.map(item => {
            return {
              value: item.id,
              text: item.description,
            };
          }));

          this.optionBlocked = this.optionBlocked.concat(mappedOptionBlocked);

        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
      }
    },

    async getBlockedReasonsEditCustomer(appId, unLock) {
      this.isLoading = true;

      const url = `${config.default.urlBase}/customers/blocked-reasons`;
      const params = await this.buildParamsBlockeReasons(appId, unLock)
      try {
        const response = await axios({
          url,
          method: "GET",
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          this.optionBlockReason = []
          this.selectedOptionBlockReason = null

          this.optionBlockReason = [{
            id: null,
            value: null,
            text: 'Selecione uma Opção'
          }];

          const mappedOptionBlockReason = await Promise.all(response.data.map((item, index) => {
            return {
              id: item.id,
              value: index + 1,
              text: item.description,
            };
          }));

          this.optionBlockReason = this.optionBlockReason.concat(mappedOptionBlockReason)
        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
      }
    },

    buildParamsCustomerHistories(appId, document, page, pageSize) {
      let params = new Object();

      params.appId = appId,
        params.document = document,
        params.page = page;
      params.pageSize = pageSize;

      return params
    },

    async searchCustomerHistories(page) {
      this.isLoading = true

      const urlHistories = `${config.default.urlBase}/customers/histories/search`;
      const params = this.buildParamsCustomerHistories(this.selectedOptionApp, this.customerHistoriesDocument, page, this.perPageCustomerHistories)


      const urlSessions = `${config.default.urlBase}/customers/sessions/search`;
      const data = this.buildDataCustomerSessions(this.customerHistoriesId, page, this.perPageCustomerSessions)

      await this.$refs.customerHistories.show()

      try {
        const responseHistories = await axios({
          url: urlHistories,
          method: "GET",
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (responseHistories.status == 200) {
          this.isLoading = false

          this.customerHistories = responseHistories.data.items

          this.paginationCustomerHistories = responseHistories.data
          this.paginationCustomerHistories.rowsPerPage = this.perPageCustomerHistories
          this.paginationCustomerHistories.paginationRange = this.paginationRangeCustomerHistories
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }

        this.customerHistories = []
        this.paginationCustomerHistories = []
      }

      try {
        const responseSessions = await axios({
          url: urlSessions,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (responseSessions.status == 200) {
          this.isLoading = false


          this.customerSessions = responseSessions.data.items

          this.paginationCustomerSessions = responseSessions.data
          this.paginationCustomerSessions.rowsPerPage = this.perPageCustomerSessions
          this.paginationCustomerSessions.paginationRange = this.paginationRangeCustomerSessions
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }

        this.customerSessions = []
        this.paginationCustomerSessions = []
      }
    },

    buildDataCustomerSessions(customerId, page, pageSize, selectedOptionDate, startDate, endDate) {
      const data = new Object();

      data.customerId = customerId;
      data.page = page;
      data.pageSize = pageSize;

      if (selectedOptionDate == 1) {
        data.startDate = startDate;
        data.endDate = endDate;
      }

      return data
    },

    async howHandler(page) {
      await this.searchCustomerSession(page)
    },

    async searchCustomerSession(page) {
      this.isLoading = true

      const url = `${config.default.urlBase}/customers/sessions/search`;
      const data = this.buildDataCustomerSessions(this.customerHistoriesId, page, this.perPageCustomerSessions, this.selectedOptionDate, this.startDate, this.endDate)

      try {
        const response = await axios({
          url,
          method: "POST",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false

          this.customerSessions = response.data.items

          this.paginationCustomerSessions = response.data
          this.paginationCustomerSessions.rowsPerPage = this.perPageCustomerSessions
          this.paginationCustomerSessions.paginationRange = this.paginationRangeCustomerSessions
        }
      } catch (error) {
        this.isLoading = false

        console.log({
          error
        })

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else if (error.response.status == 404) {
          this.getNotification(
            "Aviso",
            `${error.response.data.message}`,
            "warning"
          )
        } else {
          await this.$alert(
            `${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }


        this.customerSessions = []
        this.paginationCustomerSessions = []


      }
    },


    async searchCustomerHistory(page) {
      this.isLoading = true

      const url = `${config.default.urlBase}/customers/histories/search`;
      const params = this.buildParamsCustomerHistories(this.selectedOptionApp, this.customerHistoriesDocument, page, this.perPageCustomerHistories)

      try {
        const response = await axios({
          url,
          method: "GET",
          params,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });


        if (response.status == 200) {
          this.isLoading = false


          this.customerHistories = response.data.items

          this.paginationCustomerHistories = response.data
          this.paginationCustomerHistories.rowsPerPage = this.perPageCustomerHistories
          this.paginationCustomerHistories.paginationRange = this.paginationRangeCustomerHistories
        }
      } catch (error) {
        console.log({
          error
        })

        this.isLoading = false

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        } else {
          await this.$alert(
            `${error.response.data.message}`,
            "Aviso",
            "warning"
          );
        }

        this.customerHistories = []
        this.paginationCustomerHistories = []
      }

    },

    async showCustomerHistoriesMobile(customer) {
      this.customerHistories = []

      this.customerHistoriesDocument = customer.document
      this.customerHistoriesId = customer.id
      this.customerHistoriesName = customer.name

      await this.openModalCustomerHistories(this.currentPageCustomerHistories);
    },

    async showCustomerHistories(customer) {
      this.customerHistories = []

      this.customerHistoriesDocument = customer.item.document
      this.customerHistoriesId = customer.item.id
      this.customerHistoriesName = customer.item.name

      await this.openModalCustomerHistories(this.currentPageCustomerHistories);
    },

    async openModalCustomerHistories(page) {
      this.selectedOptionDate = null
      this.startDate = null
      this.endDate = null

      await this.searchCustomerHistories(page);
    },

    async showActionCustomerModal(customer) {
      this.customerId = customer.item.id;
      this.customerName = customer.item.name;
      this.isCustomerBlocked = customer.item.isBlocked;
      this.customerDocument = customer.item.document;

      if (this.isCustomerBlocked) {
        this.action = 'Desbloqueio do Cliente'
      } else {
        this.action = 'Bloqueio do Cliente'
      }

      this.selectedOptionBlockReason = null
      this.observation = ''

      const customerUnBlocked = this.isCustomerBlocked == 1 ? true : false

      await this.getBlockedReasonsEditCustomer(this.selectedOptionApp, customerUnBlocked)
      await this.$bvModal.show('actionCustomer')
    },

    async showActionCustomerModalMobile(customer) {
      this.customerId = customer.id;
      this.customerName = customer.name;
      this.isCustomerBlocked = customer.isBlocked;
      this.customerDocument = customer.document;

      if (this.isCustomerBlocked) {
        this.action = 'Desbloqueio do Cliente'
      } else {
        this.action = 'Bloqueio do Cliente'
      }

      this.selectedOptionBlockReason = null
      this.observation = ''

      const customerUnBlocked = this.isCustomerBlocked == 1 ? true : false

      await this.getBlockedReasonsEditCustomer(this.selectedOptionApp, customerUnBlocked)
      await this.$bvModal.show('actionCustomer')
    },

    async cancelBlockCustomer() {
      this.observation = ''
      this.action = ''
      this.$bvModal.hide('actionCustomer')
    },

    async buildCustomerBlock(isBlocked, blockedReasonId, blockedReason, document, appId, userId, userName, reasonId, reason, type) {
      return {
        isBlocked,
        blockedReasonId,
        blockedReason,
        document,
        appId,
        userId,
        userName,
        reasonId,
        reason,
        type,
      }
    },

    async confirmBlockCustomer() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/customers/${this.customerId}`;

      const data = await this.buildCustomerBlock(
        !this.isCustomerBlocked,
        this.optionBlockReason[this.selectedOptionBlockReason].id,
        this.optionBlockReason[this.selectedOptionBlockReason].text,
        this.customerDocument,
        this.selectedOptionApp,
        this.userId,
        this.userName,
        this.optionBlockReason[this.selectedOptionBlockReason].id,
        this.observation,
        this.isCustomerBlocked ? 'Unblocked Customer' : 'Blocked Customer'
      )

      try {
        const response = await axios({
          url,
          method: "PATCH",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          await this.$bvModal.hide('actionCustomer')

          await this.$alert(
            "Clientes atualizado!",
            "Sucesso",
            "success"
          );

          if (this.selectedOptionApp) {
            if (!this.selectedPageCustomer) {
              await this.getCustomers(this.currentPage);
            } else {
              await this.navigate(this.selectedPageCustomer)
            }
          }

        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;

        if (!error.response) {
          await this.$alert(
            "Não foi possível conectar-se ao servidor. Verifique a conexão e tente novamente!",
            "Erro",
            "error"
          );
        }

        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
      }
    },

    async getLocalStorage() {
      if (localStorage.getItem("selectedOptionAppCustomerPage") == 'null' || localStorage.getItem("selectedOptionAppCustomerPage") == null) {
        this.selectedOptionApp = null
      } else {
        this.selectedOptionApp = parseInt(localStorage.getItem("selectedOptionAppCustomerPage"));
      }


      if (localStorage.getItem("selectedOptionCustomerPage") == 'null' || localStorage.getItem("selectedOptionCustomerPage") == null) {
        this.selectedOption = null
      } else {
        this.selectedOption = localStorage.getItem("selectedOptionCustomerPage");
      }

      if (localStorage.getItem("customerCustomerPage") == 'null' || localStorage.getItem("customerCustomerPage") == null) {
        this.customer = ""
      } else {
        this.customer = localStorage.getItem("customerCustomerPage");
      }

      if (localStorage.getItem("selectedRadioCustomerPage") == 'null' || localStorage.getItem("selectedRadioCustomerPage") == null) {
        this.selectedRadio = null
      } else {
        this.selectedRadio = localStorage.getItem("selectedRadioCustomerPage");
      }

      if (localStorage.getItem("selectedRadioCustomerBlockCustomerPage") == 'null' || localStorage.getItem("selectedRadioCustomerBlockCustomerPage") == null) {
        this.selectedRadioCustomerBlock = null
      } else {
        this.selectedRadioCustomerBlock = localStorage.getItem("selectedRadioCustomerBlockCustomerPage");
      }

      this.selectedPageCustomer = parseInt(localStorage.getItem("selectedPageCustomerPage"));
    },

    focus(id) {
      document.getElementById(id).focus();
    },


    async buildParamsCustomerManager(customer) {

      this.modalCustomerId = customer.id
      this.modalCustomerName = customer.name
      this.modalCustomerDocument = customer.document
      this.modalCustomerPhone = customer.phone
      this.modalCustomerEmail = customer.email
      this.modalCustomerInactive = customer.inactive == 1 ? true : false
      this.modalCustomerBirthDate = moment(customer.birthDate, "DD/MM/YYYY").format("YYYY-MM-DD");
      this.modalCustomerIsChecked = customer.isChecked

      this.modalProfileImage = customer.profileImage

      this.$refs.editCustomer.show();
    },

    async buildCustomerUpdate(isChecked, name, document, phone, email, birthDate, inactive) {
      const customerUpdate = {
        name,
        phone,
        email,
        inactive
      };
    
      if (!isChecked) {
        customerUpdate.document = document
        customerUpdate.birthDate = birthDate
      }
    
      return customerUpdate;
    },

    async confirmCustomerUpdate() {
      this.isLoading = true;

      const url = `${config.default.urlBase}/customers/${this.modalCustomerId}/update`;

      const data = await this.buildCustomerUpdate(
        this.modalCustomerIsChecked,
        this.modalCustomerName,
        this.modalCustomerDocument,
        this.modalCustomerPhone,
        this.modalCustomerEmail,
        this.modalCustomerBirthDate,
        this.modalCustomerInactive
      )

      try {
        const response = await axios({
          url,
          method: "PUT",
          data,
          headers: {
            authorization: `Bearer ${this.token}`
          },
        });

        if (response.status == 200) {
          this.isLoading = false;

          await this.$bvModal.hide('editCustomer')


          await this.$alert(
            "Clientes atualizado!",
            "Sucesso",
            "success"
          );

          if (this.selectedOptionApp) {
            if (!this.selectedPageCustomer) {
              await this.getCustomers(this.currentPage);
            } else {
              await this.navigate(this.selectedPageCustomer)
            }
          }

        }
      } catch (error) {
        console.log({
          error
        });

        this.isLoading = false;


        if (error.response.status == 403) {
          await this.$alert(
            "Sua sessão expirou. Faça o login novamente!",
            "Aviso",
            "warning"
          );
          this.$router.push("login");
        }
      }
    },

    cancelCustomerUpdate() {
      this.$bvModal.hide('editCustomer')
    }
  },

  async beforeMount() {
    this.isLoading = true;
    this.token = localStorage.getItem("token");
    this.typeUser = localStorage.getItem("typeUser");
    this.userId = parseInt(localStorage.getItem("idUsuarioLogado"));
    this.userName = localStorage.getItem("usuarioLogado");

    await this.getLocalStorage();

    await this.getApps();
    this.isLoading = false;
  },
};